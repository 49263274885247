.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mt-4 {
  margin-top: 40px !important;
}

.mt-5 {
  margin-top: 50px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.mb-4 {
  margin-bottom: 40px !important;
}

.mb-5 {
  margin-bottom: 50px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.ml-3 {
  margin-left: 30px !important;
}

.ml-4 {
  margin-left: 40px !important;
}

.ml-5 {
  margin-left: 50px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mr-3 {
  margin-right: 30px !important;
}

.mr-4 {
  margin-right: 40px !important;
}

.mr-5 {
  margin-right: 50px !important;
}

.fl-r {
  float: right;
}

.fl-l {
  float: left;
}

.fw-bold {
  font-weight: bold;;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.cs-pointer {
  cursor: pointer;
}

.ant-input {
  font-weight: normal;
}

.ant-checkbox-checked .ant-checkbox-inner, 
.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after,
.ant-radio-inner:after,
.ant-switch-checked {
  background-color: #219653;
  border-color: #219653;
}

.ant-checkbox-checked:after,
.ant-radio-checked:after,
.ant-radio-checked .ant-radio-inner {
  border: 1px solid #219653;
}

.ant-form-item {
  margin-bottom: 15px;
}

.ant-menu-item-icon img {
  width: 24px;
  height: 24px;
  padding: 4px;
}


.ant-table, 
.ant-radio-wrapper,
.ant-select-selection-item,
input,
select {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: white;
}

.modify-options {
  width: 250px !important;
  min-width: 250px !important;
}

.ant-table-filter-dropdown,
.ant-table-filter-trigger {
  visibility: hidden;
}